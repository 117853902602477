import React, { useState } from 'react';
import './ContactCard.scss';
// Import assets
import callIcon from '../assets/call1.png';
import emailIcon from '../assets/email1.png';
// import locationIcon from "../assets/location1.png";
import bankIcon from '../assets/bank.png';
import paypalIcon from '../assets/paypal.png';
import copyIcon from '../assets/link.png';

const contactMethods = [
	// {
	// 	id: 1,
	// 	imgSrc: callIcon,
	// 	altText: 'Call icon',
	// 	title: 'Call Us:',
	// 	detail: '+49 15566 097538'
	// },
	{
		id: 1,
		imgSrc: emailIcon,
		altText: 'Email icon',
		title: 'Email Us:',
		detail: 'hello@kadamba.com'
	}
	// {
	//   id: 3,
	//   imgSrc: locationIcon,
	//   altText: "Visit icon",
	//   title: "Visit Us:",
	//   detail: "Somewhere in the World",
	// },
];

interface ContactMethodProps {
	imgSrc: string;
	altText: string;
	title: string;
	detail: string;
}
const ContactMethod: React.FC<ContactMethodProps> = ({ imgSrc, altText, title, detail }) => (
	<div className='col-md-4 d-flex align-items-center'>
		<img
			src={imgSrc}
			className='img-fluid'
			alt={altText}
		/>
		<div className='ms-2'>
			<p className='mb-0 text-white'>{title}</p>
			<p className='text-white'>{detail}</p>
		</div>
	</div>
);

// export const ContactCard = () => (
// 	<section className='contactCard__section mt-4 mb-5'>
// 		<div className='card py-md-3'>
// 			<div className='card-body'>
// 				<h4 className='text-white text-uppercase text-start'>Get in touch now for subscription request</h4>
// 				<small className='text__gray__three'>
// 					For inquiries or to begin your meal box lunch experience today, please feel free to reach out to us
// 					via phone call or email. Alternatively, you are welcome to visit us in person.
// 				</small>
// 				<div className='row mt-5'>
// 					{contactMethods.map(method => (
// 						<ContactMethod
// 							key={method.id}
// 							{...method}
// 						/>
// 					))}
// 				</div>
// 			</div>
// 		</div>
// 	</section>
// );

type PaymentMethodProps = {
	isCopied: boolean;
	setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
	text: string;
	children: React.ReactNode;
};
const PaymentMethod: React.FC<PaymentMethodProps> = ({ isCopied, setIsCopied, text, children }) => {
	const copyToClipboard = (text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setIsCopied(true);
				setTimeout(() => setIsCopied(false), 2000);
			})
			.catch(err => console.error('Failed to copy text: ', err));
	};

	return (
		<div
			onClick={() => copyToClipboard(text)}
			style={{ position: 'relative' }}
			className='copy__text text__gray__three mt-4 px-3 py-2'
		>
			{children}
			<img
				src={copyIcon}
				alt='copy'
				className='img-fluid ms-2'
			/>
			{isCopied && <span className='copied'>Copied!</span>}
		</div>
	);
};

export const PaymentCard = () => {
	const [isCopied, setIsCopied] = useState(false);

	return (
		<section className='contactCard__section'>
			<div className='card py-md-3'>
				<div className='card-body'>
					<h4 className='text-white text-uppercase text-start'>Payment Detail</h4>
					<small className='text__gray__three'>
						You can make a payment directly to our bank using the provided details, or you can opt to use
						PayPal. We accept both methods.
					</small>
					<div className='row align-items-center mt-md-5'>
						<div className='col-md-6'>
							<div className='row mt-5'>
								<div className='col-2'>
									<img
										src={bankIcon}
										alt='bank'
									/>
								</div>
								<div className='col-10'>
									<p className='text-white fw-bold'>GLS Bank</p>
									<p className='text__light__white'>Kontoinhaber: ISKCON Berlin e. V.</p>
									<p className='text__light__white'>IBAN: DE95430609671312917000</p>
									<p className='text__light__white'>BIC: GENODEM1GLS</p>
									<p className='text__light__white'>Bank: GLS Gemeinschaftsbank</p>
									<p className='text__light__white'>4789 Bochum</p>
								</div>
							</div>
						</div>
						<div className='col-md-4 offset-md-2 text-center mt-md-0 mt-4'>
							<img
								src={paypalIcon}
								alt='paypal'
								className='img-fluid'
							/>
							{/* <PaymentMethod
								isCopied={isCopied}
								setIsCopied={setIsCopied}
								text='hello@kadamba-foods.com'
							>
								hello@kadamba-foods.com
							</PaymentMethod> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
